import React from 'react';
import { useTranslation } from 'react-i18next';
import { EndpointDataPropTypes, InlineDataPropTypes } from './utils/propTypes';
import Carousel from './components/Carousel/Carousel';
import i18n from '../common/utils/i18n';

function App({ endpointData, inlineJsonData }) {
  i18n.addResourceBundle('en', 'translation', inlineJsonData.translations);
  const { t } = useTranslation();
  return (
    <div className="contentGroup ds-section">
      <h2 className="contentGroup__title">
        {t('shortVideos')}
      </h2>
      <Carousel data={endpointData} />
    </div>
  );
}

App.propTypes = {
  endpointData: EndpointDataPropTypes.isRequired,
  inlineJsonData: InlineDataPropTypes.isRequired,
};

export default App;
