import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { EndpointDataPropTypes } from '../../utils/propTypes';
import InstaCard from '../InstaCard/InstaCard';
import InstaModal from '../InstaModal/InstaModal';
import setupFlickity from '../../../../js/utils/setupFlickity';
import 'flickity/css/flickity.css';
import './Carousel.scss';

function Carousel({ data }) {
  const [selectedInsta, setSelectedInsta] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const flickityOption = {
    pageDots: false,
    groupCells: true,
    freeScroll: true,
    wrapAround: true,
    watchCSS: true,
    draggable: isMobile,
  };

  const handleInstaCardClick = (insta, index) => {
    setSelectedInsta(insta);
    setSelectedIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedInsta(null);
    setSelectedIndex(null);
  };

  const handleNextInsta = () => {
    const nextIndex = (selectedIndex + 1) % data.length;
    setSelectedInsta(data[nextIndex]);
    setSelectedIndex(nextIndex);
  };

  const handlePreviousInsta = () => {
    const prevIndex = (selectedIndex - 1 + data.length) % data.length;
    setSelectedInsta(data[prevIndex]);
    setSelectedIndex(prevIndex);
  };

  useEffect(() => {
    setupFlickity('.ds-instaWidgetCarousel', flickityOption);
  }, []);

  return (
    <div className="contentGroup__content ds-instaWidgetCarousel">
      <div className="ds-flickityMobileSwipeCards">
        <ul className="carousel">
          {data.map((insta, index) => (
            <InstaCard
              key={insta.id}
              insta={insta}
              onClick={() => handleInstaCardClick(insta, index)}
            />
          ))}
        </ul>
      </div>
      {selectedInsta && (
        <InstaModal
          insta={selectedInsta}
          onClose={handleCloseModal}
          onNext={handleNextInsta}
          onPrev={handlePreviousInsta}
          showInstaModal={!!selectedInsta}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

Carousel.propTypes = {
  data: EndpointDataPropTypes.isRequired,
};

export default Carousel;
